@keyframes createBox {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes createBoxY {
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
}

@keyframes createBoxYX {
  0% {
    transform: scale(0.5, 0);
  }

  50% {
    transform: scale(0.5, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

div.animate-box-in {
  animation: createBox 0.25s;
}

div.animate-box-in-y {
  animation: createBoxY 0.25s;
  transform-origin: top;
}

div.animate-box-in-xy {
  animation: createBoxXY 0.35s;
}

body {
  margin: 0;
  background-color: #2c2e37;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nevigation-bar {
  display: flex;
  justify-content: space-around;
}

.text-center {
  text-align: center;
}

.pad-x-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.flex-justify-start {
  display: flex;
  justify-content: flex-start;
}

.flex {
  display: flex;
}

.forecast.sec-conteiner {
  margin-left: 1em;
  border: 1px solid rgb(236, 199, 199);
  background-color: #65a1a10d;
  padding: 1px 13px;
  border-radius: 4px;
  margin-bottom: 2px;
}

.forecast-top.title-f {
  font-size: 12px;
}

.flex-alignItems-center {
  display: flex;
  align-items: center;
}

.min-vh-60 {
  min-height: 60vh;
}

.mobile-logo {
  position: absolute;
  top: 4px;
  left: 0;
}

.MuiAlert-message {
  margin-right: 0.5rem;
}

.MuiCardContent-root.css-gwqzf-MuiCardContent-root:last-child {
  padding-bottom: 0;
}

.css-1gw61d3:last-child {
  padding-bottom: 0 !important;
}

.cam-container {
  max-width: 800px;
  margin: 0 auto;
}

.marging-top-bottom {
  margin-top: 0;
  margin-bottom: 0%;
}

.meshulam-iframe-container,
.tranzila-iframe-container {
  max-height: 900px;
  order: 2;
  /* width: 100%; */
}

iframe.tranzila1-iframe {
  background-color: white;
  padding-top: 0;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  /* box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%); */
}

.privacy-policy {
  padding: 0 1em;
  max-width: 900px;
  margin: 0 auto;
}

.MuiInputBase-input.MuiInput-input {
  border-bottom: 1px solid #e7bd75!important;
}

.forcastComponent {
  max-width: 800px;
  margin: 0 auto;
  background-color: "#aca7a761";
}

.credit-only .content .top >span {
  display: block;
  text-align: center;
  font-size: 23px!important;
  font-family: Heebo-Medium;
  padding: 0 64px;
}

.bit_iframe {
  z-index: 100;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 0;
  top: 0;
  right: 0;
  background-color: white!important;
  /* max-width: 100vw; */
}

.meshulam-iframe-container {
  border-radius: 2px;
}

.video-report {
  max-height: 500px;
}

@media screen and (max-width: 600px) {
  .cam-container .cam-iframe {
    max-height: 32vh;
  }

  .video-report {
    max-height: 32vh;
  }
}
